<template>
    <div class="row d-flex flex-wrap" role="group">
        <div class="col">
            <template v-if="type == 'password'">
                <v-text-field
                    v-model="model"
                    :label="label"
                    :rules="computeFieldRules()"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    dense
                    :hint="hint"
                    persistent-hint
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @keydown.enter.prevent="handleEnter()"
                    :clearable="clearable"
                    @input="handleInput"
                ></v-text-field>
            </template>
            <template v-else>
                <v-text-field
                    v-model="model"
                    :label="label"
                    :rules="computeFieldRules()"
                    :type=type
                    dense
                    :hint="hint"
                    persistent-hint
                    hide-details="auto"
                    @keydown.enter.prevent="handleEnter()"
                    :outlined="outlined"
                    :append-icon="icon"
                    :clearable="clearable"
                    :disabled="disabled"
                ></v-text-field>
                <template v-if="semaforo">
                    <p style="color: orange;">{{ mensajeSemaforo }}</p>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    import { validateRut } from '@fdograph/rut-utilities';
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            type: {
                type: String
            },
            hint: {
                type: String
            },
            required: {
                type: Boolean,
                default: false
            },
            valor: {
                type: String
            },
            disabled: {
                type: Boolean,
                default: false
            },
            tipoCoordenada: {
                type: String
            },
            semaforo: {
                type: Boolean,
                default: false
            },
            outlined: {
                type: Boolean,
                default: true
            },
            clearable: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                rules: {
                    required: (val) => (val || "").length > 0 || "Este campo es obligatorio.",
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'Email inválido.'
                    },
                    rut: value => {
                        const isValid = validateRut(value);
                        return isValid || 'RUT inválido';
                    },
                    latitud: value => {
                        if (value) {
                            if (this.tipoCoordenada == 'DEC') { 
                                if (isNaN(value) || value < -56 || value > -16 && this.tipoCoordenada == 'DEC') {
                                    return 'Latitud invalida';
                                }
                            }
                        }
                        return true
                    },
                    longitud: value => {
                        if (value) {
                            if (this.tipoCoordenada == 'DEC') {
                                if (isNaN(value) || value < -78 || value > -66) {
                                    return 'Longitud invalida';
                                }
                            }
                        }
                        return true
                    },
                    semaforo: value => {
                        if (value != null && value != "" && value.length > 0) {
                            this.mensajeSemaforo = ''
                        } else {
                            this.mensajeSemaforo = 'Campo necesario para completar compromiso'
                        }
                        return true
                    },
                    numerico: value => {
                        if (!/^\d+$/.test(value)) {
                            return 'Este campo solo puede contener números.';
                        }
                        return true;
                    }
                },
                modelo: '',
                show1: false,
                mensajeSemaforo: '',
                editado: false
            }
        },
        methods: {
            getValue() {
                return this.modelo;
            },
            setValue(value) {
                this.modelo = value;
            },
            computeFieldRules() {
                const commonRules = this.required ? [this.rules.required] : [];
                if (this.type === 'email' && this.required) {
                    return [...commonRules, this.rules.email];
                } else if (this.type === 'rut') {
                    return [...commonRules, this.rules.rut];
                } else if (this.type === 'latitud') {
                    return [...commonRules, this.rules.latitud]
                } else if (this.type === 'longitud') {
                    return [...commonRules, this.rules.longitud]
                } else if (this.type === 'numerico') {
                    return [...commonRules, this.rules.numerico]
                }else {
                    if (this.semaforo) {
                        return [...commonRules, this.rules.semaforo]
                    }
                    return commonRules;
                }
            },
            getEditado() {
                return this.editado
            },
            setEditado(editado) {
                this.editado = editado
            },
            handleEnter() {
                this.$emit('enter', this.modelo);
            },
            handleInput() {
                this.$emit('input', this.modelo)
            }
        },
        computed: {
            model: {
                get: function() {
                    return this.modelo
                },
                set: function(newValue) {
                    if ((this.model && this.model != '') && (newValue && newValue != '')) {
                        this.editado = true
                    }
                    this.modelo = newValue;
                }
            }
        },
        mounted() {
            this.model = this.valor
        }
    }
</script>
<style>
.text-green input {
    color: rgb(0 255 128);
}
</style>
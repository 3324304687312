<template>
    <div class="mb-4">
        <div class="text-center">
            <v-snackbar v-model="snackbar" :timeout="timeout" :top="true" :color="snackbarColor" min-width="0">
                <v-col>
                    {{ snackbarText }}
                </v-col>
                <template v-slot:action="{ attrs }">
                    <v-col>
                        <v-btn color="red" icon size="x-small" v-bind="attrs" @click="snackbar = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                </template>
            </v-snackbar>
        </div>
        <div>
            <div class="d-flex">
                <v-row>
                    <v-col cols="3" class="ml-2 mt-3">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Buscar..."
                            outlined
                            dense
                            single-line
                            hide-details
                        ></v-text-field>
                        <!--<text-field-component v-model="search" single-line hide-details label="Buscar..." icon="mdi-magnify"></text-field-component>-->
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="4" class="mr-5 mt-1 justify-end text-end">
                        <template v-if="showExport">
                            <v-btn color="success" class="ml-5 mt-2" @click="downloadExl" :disabled="isExportActive"
                                prepend-icon="mdi-file-excel">
                                Exportar
                            </v-btn>
                        </template>
                    </v-col>
                </v-row>
            </div>
            <div class="table-responsive">
                <v-data-table
                    :headers="headers"
                    :items="datos"
                    :sort-by.sync="sortBy" 
                    :sort-desc.sync="sortDesc"
                    @update:sort-by="updateSort('by', $event)" 
                    @update:sort-desc="updateSort('desc', $event)" 
                    class="mt-3 no-wrap" 
                    id="myDataTable" 
                    :height="altoTabla"
                    :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-minus',
                            nextIcon: 'mdi-plus',
                            'items-per-page-text':'Registros por página',
                            'items-per-page-options': [5, 10, 15, 100]
                        }"
                    :loading="loading"
                    loading-text="Cargando... Por favor espere"
                    :search="search"
                    :custom-filter="filterSearch"
                    no-data-text="No se encontraron datos"
                    mobile-breakpoint="100"
                >
                    <template v-slot:body="{ items }">
                        <tr v-if="items.length === 0 && loading == false">
                            <td :colspan="headers.length">
                                <v-alert :value="true" type="info" icon="mdi-information">
                                    No se encontraron datos para mostrar.
                                </v-alert>
                            </td>
                        </tr>
                        <template v-for="dato in items">
                            <tr scope="col">
                                <td v-for="field in fields">
                                    <div v-if="field.align === 'center'">
                                        <v-layout justify-center>
                                            <div
                                                v-html="datosTabla(dato[field.key], field.tipo, field.enlace, field.key2, readOnly, field.key3, dato, field.nombreDefault, field.custom)">
                                            </div>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'nombre' && field.key2 === 'id_expediente'">
                                        <v-layout justify-center>
                                            <div
                                                v-html="datosTabla(dato[field.key2], field.tipo, field.enlace, field.key, readOnly, field.key3, dato, field.nombreDefault, field.custom)">
                                            </div>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'region'">
                                        <v-layout justify-center>
                                            <div
                                                v-html="nombreRegion(dato.region)">
                                            </div>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'ficha_expediente'">
                                        <v-layout justify-center>
                                            <v-btn class="mx-2" fab dark small color="primary"
                                                @click="ver_detalle(dato.id_expediente)"><v-icon
                                                    dark>mdi-format-list-bulleted-square</v-icon></v-btn>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'ficha_sancion'">
                                        <v-layout justify-center>
                                            <v-btn class="mx-2" fab dark small color="primary"
                                                @click="ver_detalle_sancion(dato.id)"><v-icon
                                                    dark>mdi-format-list-bulleted-square</v-icon></v-btn>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'save' && dato.tipologia === 'c'">
                                        <v-btn class="mx-2" fab dark small color="primary" @click="saveRow()"><v-icon
                                                dark>mdi-content-save</v-icon></v-btn>
                                    </div>
                                    <div v-else-if="field.tipo === 'coordenadas' && field.key === 'x'">
                                        <v-text-field v-model="dato.x" outlined :dense="true" class="mt-8" type="number"
                                            @blur="updateCoord(field.key, dato.id, dato.x)"></v-text-field>
                                    </div>
                                    <div v-else-if="field.tipo === 'coordenadas' && field.key === 'y'">
                                        <v-text-field v-model="dato.y" outlined :dense="true" class="mt-8" type="number"
                                            @blur="updateCoord(field.key, dato.id, dato.y)"></v-text-field>
                                    </div>
                                    <div v-else-if="field.key === 'descripcion_detalle'">
                                        <!-- <v-textarea
                                            rows="3"
                                            filled
                                            v-html="resaltarPalabra(dato.descripcion_detalle)"
                                            no-resize
                                            disabled
                                            full-width
                                            hide-details
                                        ></v-textarea> -->
                                        <div 
                                            v-html="resaltarPalabra(dato.descripcion_detalle)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'instrumento_infringido'">
                                        {{ dato.instrumento_infringido }} <br>
                                        <a :href="dato.link" target="_blank">Link Detalle</a>
                                    </div>
                                    <div v-else-if="field.key === 'hecho'">
                                        <div 
                                            v-html="resaltarPalabra(dato.hecho)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'clasificacion'">
                                        <div 
                                            v-html="resaltarPalabra(dato.clasificacion)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'infraccion'">
                                        <div 
                                            v-html="resaltarPalabra(dato.infraccion)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'expediente'">
                                        <a :href="'https://snifa.sma.gob.cl/Sancionatorio/Ficha/'+dato.detalle_id" target="_blank">{{ dato.expediente }}</a>
                                    </div>
                                    <div v-else-if="field.key === 'snifa_sancionatorio_uf__unidad_fiscalizable__nombre'">
                                        <a :href="'https://snifa.sma.gob.cl/UnidadFiscalizable/Ficha/'+dato.snifa_sancionatorio_uf__unidad_fiscalizable__ficha_id" target="_blank">{{ dato.snifa_sancionatorio_uf__unidad_fiscalizable__nombre }}</a>
                                    </div>
                                    <div v-else-if="field.key === 'nombre' && field.key2 === 'id_expediente'">
                                        <v-layout justify-center>
                                            <div
                                                v-html="datosTabla(dato[field.key2], field.tipo, field.enlace, field.key, readOnly, field.key3, dato, field.nombreDefault, field.custom)">
                                            </div>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'region'">
                                        <v-layout justify-center>
                                            <div
                                                v-html="nombreRegion(dato.region)">
                                            </div>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'ficha_expediente'">
                                        <v-layout justify-center>
                                            <v-btn class="mx-2" fab dark small color="primary"
                                                @click="ver_detalle(dato.id_expediente)"><v-icon
                                                    dark>mdi-format-list-bulleted-square</v-icon></v-btn>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'ficha_sancion'">
                                        <v-layout justify-center>
                                            <v-btn class="mx-2" fab dark small color="primary"
                                                @click="ver_detalle_sancion(dato.id)"><v-icon
                                                    dark>mdi-format-list-bulleted-square</v-icon></v-btn>
                                        </v-layout>
                                    </div>
                                    <div v-else-if="field.key === 'save' && dato.tipologia === 'c'">
                                        <v-btn class="mx-2" fab dark small color="primary" @click="saveRow()"><v-icon
                                                dark>mdi-content-save</v-icon></v-btn>
                                    </div>
                                    <div v-else-if="field.tipo === 'coordenadas' && field.key === 'x'">
                                        <v-text-field v-model="dato.x" outlined :dense="true" class="mt-8" type="number"
                                            @blur="updateCoord(field.key, dato.id, dato.x)"></v-text-field>
                                    </div>
                                    <div v-else-if="field.tipo === 'coordenadas' && field.key === 'y'">
                                        <v-text-field v-model="dato.y" outlined :dense="true" class="mt-8" type="number"
                                            @blur="updateCoord(field.key, dato.id, dato.y)"></v-text-field>
                                    </div>
                                    <div v-else-if="field.key === 'descripcion_detalle'">
                                        <!-- <v-textarea
                                            rows="3"
                                            filled
                                            v-html="resaltarPalabra(dato.descripcion_detalle)"
                                            no-resize
                                            disabled
                                            full-width
                                            hide-details
                                        ></v-textarea> -->
                                        <div 
                                            v-html="resaltarPalabra(dato.descripcion_detalle)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'instrumento_infringido'">
                                        {{ dato.instrumento_infringido }} <br>
                                        <a :href="dato.link" target="_blank">Link Detalle</a>
                                    </div>
                                    <div v-else-if="field.key === 'snifa_sancionatorios_hechos__hecho'">
                                        <div 
                                            v-html="resaltarPalabra(dato.snifa_sancionatorios_hechos__hecho)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'clasificacion'">
                                        <div 
                                            v-html="resaltarPalabra(dato.clasificacion)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'infraccion'">
                                        <div 
                                            v-html="resaltarPalabra(dato.infraccion)"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else-if="field.key === 'expediente'">
                                        <a :href="'https://snifa.sma.gob.cl/Sancionatorio/Ficha/'+dato.detalle_id" target="_blank">{{ dato.expediente }}</a>
                                    </div>
                                    <div v-else-if="field.key === 'snifa_sancionatorio_uf__unidad_fiscalizable__nombre'">
                                        <a :href="'https://snifa.sma.gob.cl/UnidadFiscalizable/Ficha/'+dato.snifa_sancionatorio_uf__unidad_fiscalizable__ficha_id" target="_blank">{{ dato.snifa_sancionatorio_uf__unidad_fiscalizable__nombre }}</a>
                                    </div>
                                    <div v-else-if="field.key === 'comuna'">
                                        <div
                                            v-html="dato.comuna"
                                            class="scrollable-div"
                                        ></div>
                                    </div>
                                    <div v-else>
                                        <div
                                            v-html="datosTabla(dato[field.key], field.tipo, field.enlace, field.key2, readOnly, field.key3, dato, field.nombreDefault, field.custom)">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                </v-data-table>
            </div>
        </div>
        <!-- <div v-if="sinDatos">
            <v-alert
                color="blue"
                type="info"
            >No se encontraron registros</v-alert>
        </div> -->
    </div>
</template>

<script>

import axios from 'axios'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    props: {
        fields: {
            type: Array,
            required: true
        },
        currentPage: {
            type: String,
            required: true
        },
        params: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            required: true
        },
        paginable: {
            type: Boolean,
            required: true
        },
        pagina: {
            type: Number,
            required: false
        },
        altoTabla: {
            type: Number,
        }
    },

    data() {
        return {
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            timeout: 30000,
            datos: [],
            paginaActual: this.currentPage,
            storage_page: this.currentPage,
            showExport: false,
            isExportActive: false,
            sub: 'Ingrese tipo',
            subtipos: ["Hidráulica", "Solar", "Eólica", "Térmica", "Bioenergia", "Geotérmica", "Almacenamiento", "Hidrógeno", "Híbrido", "No identificada"],
            page: parseInt(this.pagina),
            totalPages: 0,
            sub_tipologia: "",
            listCoordById: [],
            potencia: "",
            activator: null,
            attach: null,
            colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange'],
            editing: null,
            editingIndex: -1,
            items: [
                { header: 'Select an option or create one' },
                {
                    text: 'Foo',
                    color: 'blue',
                },
                {
                    text: 'Bar',
                    color: 'red',
                },
            ],
            nonce: 1,
            menu: false,
            model: [
                {
                    text: 'Foo',
                    color: 'blue',
                },
            ],
            x: 0,
            search: '',
            y: 0,
            headers: [],
            sortBy: [],
            sortDesc: false,
            validado: ['', 'Validado'],
            label_validado: '',
            validado_data: '',
            count: 0,
            perPage: 10,
            elementosPorPagina: 1,
            total: 1,
            pageCount: 0,
            loading: false,
            datosAux: [],
            sinDatos: true,
        }
    },
    methods: {
        updateSort(byDesc, event) {
            if (byDesc == 'by') {
                this.sortBy = event
            } else if (byDesc == 'desc') {
                this.sortDesc = event
            }
            this.getData()
        },
        ver_detalle(id) {
            localStorage.setItem('id_expediente', id)
            localStorage.setItem('payload', this.params)
            localStorage.setItem('currentPage', this.currentPage)
            localStorage.setItem('page', this.page)
            this.$router.replace({ name: 'detalles' })
        },
        ver_detalle_sancion(id) {
            localStorage.setItem('id_sancion', id)
            localStorage.setItem('payload', this.params)
            localStorage.setItem('currentPage', this.currentPage)
            localStorage.setItem('page', this.page)
            this.$router.replace({ name: 'detalles-sancion' })
        },
        async getData() {
            this.loading = true
            this.datos = []
            if (!this.paginable) {
                const path = this.currentPage + this.params
                await axios.get(path).then((response) => {
                    this.datos = response.data;
                    this.totalPages = 1
                    this.showExport = false
                    this.paginaActual = path
                    this.loading = false
                }).catch((error) => {
                    console.error(error)
                    this.loading = false
                })
            } else {
                let path = ''
                if (this.sortBy[0] != null) {
                    let nombreOrden = this.sortBy[0]
                    if (this.sortBy[0] == 'region_nombre') {
                        nombreOrden = 'regiones__nombre'
                    }
                    path = this.currentPage + this.page + "&page_size=" + this.perPage + this.params + '&orden=' + nombreOrden + '&tipo_orden='
                    if (this.sortDesc) {
                        path = path + 'DESC'
                    } else {
                        path = path + 'ASC'
                    }
                } else {
                    path = this.currentPage + this.page + "&page_size=" + this.perPage + this.params
                }
                await axios.get(path).then((response) => {
                    this.datos = response.data;
                    this.datosAux = response.data
                    if (this.datos.length > 0) {
                        if (this.datos.length == 0) {
                            this.showExport = false
                            this.sinDatos = true
                        } else {
                            this.showExport = true
                            this.sinDatos = false
                        }
                    }
                    this.$parent.loading = false
                    this.loading = false
                }).catch((error) => {
                    console.error(error)
                    this.loading = false
                })
            }

        },
        downloadExl() {
            this.isExportActive = true
            axios.get(this.currentPage + this.params + "&todos=Y").then((response) => {
                if (response.data) {
                    this.proyectosExcel = response.data
                    let data = XLSX.utils.json_to_sheet(this.proyectosExcel);
                    const workbook = XLSX.utils.book_new();
                    const filename = "Datos_exportados";
                    XLSX.utils.book_append_sheet(workbook, data, filename);
                    XLSX.writeFile(workbook, `${filename}.xlsx`);
                    this.isExportActive = false
                }
            }).catch((error) => {
                console.error(error)
            })
        },
        snackbarMessage(message, color) {
            this.snackbarText = message;
            this.snackbarColor = color;
            this.snackbar = true;
        },
        updateCoord(field, id, value) {
            this.snackbar = false;
            const result = this.datos.find(exp => exp.id === id);
            if (field === 'x') {
                if (!this.validarX(value)) {
                    result.x = ''
                    this.snackbarMessage("Latitud no válida, registro no guardado", "#d32f2f")
                    return
                }
                result.x = value
            } else {
                if (!this.validarY(value)) {
                    result.y = ''
                    this.snackbarMessage("Longitud no válida, registro no guardado", "#d32f2f")
                    return
                }
                result.y = value
            }
            let path = process.env.VUE_APP_BACKEND_URL + '/'
            axios.post(path, result)
                .then(data => {
                    /* console.log(data) */
                })
                .catch(function (error) {
                    console.error(error)
                })
        },
        datosTabla(value, tipo, enlace, key2, readOnly, key3, array, nombreDefault, custom) {
            if (tipo) {
                switch (tipo) {
                    case 'link':
                        if (key2) {
                            if (key3) {
                                return '<a href="' + enlace + value[key2] + '" target="_blank">' + value[key3] + '</a>'
                            }
                            else {
                                return '<a href="' + enlace + value[key2] + '" target="_blank">ver</a>'
                            }
                        } else {
                            if (key3) {
                                return '<a href="' + enlace + value + '" target="_blank">' + value[key3] + '</a>'
                            }
                            else {
                                return '<a href="' + enlace + value + '" target="_blank">ver</a>'
                            }
                        }
                        break;
                    case 'link-name':
                        if (key2) {
                            if (value[key2]) {
                                return '<a href="' + enlace + value[key2] + '" target="_blank">' + array[key3] + '</a>'
                            } else {
                                let valorRenderizado = array[key2].replace(/\s/g, ' ').replace(/&nbsp;/g, ' ');
                                return '<a href="' + enlace + value + '" target="_blank">' + valorRenderizado + '</a>'
                            }
                        } else {
                            if (nombreDefault) {
                                return '<a href="' + enlace + value + '" target="_blank">' + nombreDefault + '</a>'
                            } else if (custom) {
                                if (value) {
                                    let enlaces = ''
                                    const patron = /^(.*)\[Ficha: (\d+)\]$/
                                    let ufs = value.split('|')
                                    ufs.forEach(element => {
                                        let resultado = element.match(patron)
                                        if (resultado) {
                                            let textoFueraCorchetes = resultado[1].trim()
                                            let numeroDentroCorchetes = resultado[2]
                                            enlaces += '<a href="' + enlace + numeroDentroCorchetes + '" target="_blank">' + textoFueraCorchetes + '</a><br>'
                                        }
                                    });
                                    return enlaces
                                }
                            } else {
                                return '<a href="' + enlace + value + '" target="_blank">' + array[key3] + '</a>'
                            }
                        }
                        break;
                    case 'link-name-custom':
                        return '<a href="' + enlace + array.detalle_id + '" target="_blank">' + array.expediente + '</a>'
                    break
                    case 'fecha':
                        return this.formatoFecha(value)
                        break;
                    case 'descripcion-link':
                        return value + '<br>' + key2
                        break
                    default:
                        break;
                }

            } else {

                if (key2) {
                    return value[key2]
                } else {
                    return value
                }

            }

        },
        formatoFecha(fecha) {
            if (fecha) {
                if (fecha.split('-')[0].length > 2) {
                    let yyyy = fecha.split('-')[0]
                    let mm = fecha.split('-')[1]
                    let dd = fecha.split('-')[2]
                    return dd + '-' + mm + '-' + yyyy
                } else {
                    return fecha
                }
            }
        },
        filter(item, queryText, itemText) {
            if (item.header) return false

            const hasValue = val => val != null ? val : ''

            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return text.toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1
        },
        setHeaders() {
            this.fields.forEach(element => {
                this.headers.push({ text: element.label, value: element.key, width: element.width ? element.width : '100px', align: 'center', })
            })
        },
        validarX(coordenada) {
            if (coordenada) {
                /*if (!coordenada.match(/^[-]?\d+[\.]?\d*, [-]?\d+[\.]?\d*$/)) {
                    return false;
                }*/
                return (coordenada > -78 && coordenada < -66)
            }
            return false
        },
        validarY(coordenada) {
            if (coordenada) {
                /*if (!coordenada.match(/^[-]?\d+[\.]?\d*, [-]?\d+[\.]?\d*$/)) {
                    return false;
                }*/
                return (coordenada > -56 && coordenada < -16)
            }
            return false
        },
        formatPage() {
            if (this.count != 0 && this.perPage != 0) {
                return Math.round(this.count/this.perPage)
            } else {
                return 0
            }
        },
        valorBusqueda(valor) {
            this.datos = this.datosAux
            if(valor){
                this.datos = this.datos.filter(project => {
                    return project.nombre.toLowerCase().includes(valor.toLowerCase());
                });
            } else {
                this.datos = this.datosAux
            }
        },
        nombreRegion(region) {
            return region.replace(/Región de |Región del |Región /, "");
        },
        filterSearch(value, search, item) {
            return value != null &&
                search != null &&
                typeof value === 'string' &&
                value.toString().replaceAll('á','a').replaceAll('é','e').replaceAll('í','i').replaceAll('ó','o').replaceAll('ú','u').toLowerCase().indexOf(search.replaceAll('á','a').replaceAll('é','e').replaceAll('í','i').replaceAll('ó','o').replaceAll('ú','u').toLowerCase()) !== -1
        },
        resaltarPalabra(text) {
            if (text) {
                const palabraEscapada = this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                const regex = new RegExp(`(${palabraEscapada})`, 'gi');
                //const limitedText = text.length > 100 ? text.slice(0, 100) + '...' : text;
                const highlightedText = text.replace(regex, '<span style="font-weight: bold; background-color: yellow;">$1</span>');
                return `<div>${highlightedText}</div>`;
            }
        }
    },

    async created() {
        await this.getData()
        this.setHeaders()
    },

    watch: {
        model(val, prev) {
            if (val.length === prev.length) return

            this.model = val.map(v => {
                if (typeof v === 'string') {
                    v = {
                        text: v,
                        color: this.colors[this.nonce - 1],
                    }

                    this.items.push(v)

                    this.nonce++
                }

                return v
            })
        }
    },
}

</script>

<style>
#myDataTable {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

}

#myDataTable thead th {
    background-color: #3a8be7;
    color: #ffffff;
    text-align: center;
    column-width: 100%;
}


#myDataTable td {
    text-align: center;
    padding: 8px;
}

#myDataTable tr:nth-child(even) {
    background-color: #dddddd;
}

::v-deep .v-snack__wrapper {
    min-width: 0px;
}

.scrollable-div {
  max-height: 100px;
  overflow-y: auto; /* Esto permite el scroll vertical */
}
.table-responsive {
  overflow-x: auto;
}
.v-data-table-header th {
  white-space: nowrap;
}
</style>
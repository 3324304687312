<template>
    <div>
        <v-dialog v-model="dialog" persistent class="dialog-top" max-width="800px">
            <v-card>
                <v-card-title class="d-flex justify-center" style="font-size: 24px;">Novedades<v-progress-circular v-if="cargando" class="ml-4" indeterminate></v-progress-circular></v-card-title>
                <v-divider class="mr-8 ml-8"></v-divider>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12">
                            <template v-for="novedades in data">
                                <h4 class="mt-4">{{ novedades.created_at }}</h4>
                                <p>{{ novedades.novedad }}</p>
                            </template>
                            <!-- <tabla-component
                                :fields="fields"
                                :readOnly="true"
                                :altoTabla="400"
                                :currentPage="currentPage"
                                :params="payload"
                                :paginable="true"
                                :key="reload"
                            ></tabla-component> -->
                        </v-col>
                        <div class="text-center">
                            <v-btn class="ml-3" color="error" @click="cancelDialog()" :disabled="cargando">Cerrar</v-btn>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import TablaComponent from './TablaComponent.vue';
    import axios from 'axios'

    export default {
        components: {
            TablaComponent
        },
        data() {
            return {
                dialog: false,
                fields: [
                    { key: 'novedad', label: 'Novedad' },
                    { key: 'created_at', label: 'fecha' },
                ],
                currentPage: '',
                payload: '',
                reload: 0,
                data: [],
                cargando: false
            }
        },
        mounted() {
            //this.getNovedades()
        },
        methods: {
            async open() {
                this.dialog = true
                this.cargando = true
                await axios.get(process.env.VUE_APP_BACKEND_URL + "/novedades/").then((response) => {
                    this.data = response.data;
                    this.cargando = false
                }).catch((error) => {
                    this.cargando = false
                    console.error(error)
                })
            },
            async cancelDialog() {
                this.dialog = false
                const params = {
                    email: localStorage.getItem('user')
                }
                await axios.put(process.env.VUE_APP_BACKEND_URL + "/novedades/update/", params).then((response) => {
                    //console.log(response)
                }).catch((error) => {
                    console.error(error)
                })
            },
            getNovedades() {
                this.currentPage = process.env.VUE_APP_BACKEND_URL + "/novedades/?page="
            }
        }
    };
</script>

<style scoped>
    .dialog-top .v-dialog__content {
        align-items: flex-start !important; /* Alinea el diálogo arriba */
    }
    .dialog-top .v-dialog {
        margin-top: 20px; /* Ajusta la distancia desde la parte superior */
        z-index: 202;
        max-width: 800px !important;
    }
</style>

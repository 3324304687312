import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/components/PaginaPrincipal')
  },
  {
    path: '/busqueda',
    name:'busqueda',
    component: () => import('@/components/Busqueda')
  },
  {
    path: '/evaluacion',
    name: 'evaluacion',
    component: () => import('@/components/ListaDocs')
  },
  {
    path: '/graficos',
    name: 'graficos',
    component: () => import('@/components/chart/ChartContenedor') 
  },
  {
    path: '/mapa',
    name: 'mapa',
    component: () => import('@/components/Mapa') 
  },
  {
    path: '/detalles/',
    name: 'detalles',
    component: () => import('@/components/FichaProyecto')
  },
  {
    path: '/detalles/sancion',
    name: 'detalles-sancion',
    component: () => import('@/components/FichaProyectoSancion')
  },
  {
    path: '/expediente/:id_expediente',
    name: 'expediente',
    component: () => import('@/components/FichaProyecto')
  },
  {
    path: '/avanzada',
    name: 'avanzada',
    component: () => import('@/components/BusquedaAvanzada')
  },
  {
    path: '/registro',
    name: 'registro',
    component: () => import('@/components/Registro')
  },
  {
    path: '/verificar-correo/:uidb64/:token',
    name: 'verificar-correo',
    component: () => import('@/components/VerificarCorreo'),
    props: true
  },
  {
    path: '/nueva-constrasena/:uidb64/:token/:cambioContrasena',
    name: 'nueva-contrasena',
    component: () => import('@/components/VerificarCorreo'),
    props: true
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login'),
  },
  {
    path: '/cambio/contrasena',
    name: 'cambio-contrasena',
    component: () => import('@/components/CambioContrasena'),
  },
  {
    path: '/busqueda/snifa',
    name: 'busquedaSnifa',
    component: () => import('@/components/BusquedaSnifa'),
  },
  {
    path: '/busqueda/hechos',
    name: 'busquedaSnifaHechos',
    component: () => import('@/components/BusquedaSnifaHechos'),
  },
  {
    path: '/avanzada-snifa',
    name: 'avanzada-snifa',
    component: () => import('@/components/BusquedaAvanzadaSnifa'),
  },
  {
    path: '/administracion/empresas',
    name: 'administracion-empresas',
    component: () => import('@/components/AdministracionEmpresas'),
  },
  {
    path: '/administracion/usuarios',
    name: 'administracion-usuarios',
    component: () => import('@/components/AdministracionUsuarios'),
  },
  {
    path: '/administracion/ingresos',
    name: 'administracion-ingresos',
    component: () => import('@/components/AdministracionIngresos'),
  },
  {
    path: '/administracion/novedades',
    name: 'administracion-novedades',
    component: () => import('@/components/AdministracionNovedades.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
